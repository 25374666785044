@use '@angular/material' as mat;
@use 'vars.scss';
@use 'sass:math';
@use 'sass:map';

@mixin absolute-stretch-to-parent() {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

/**
* Flex utility
*/
@mixin equal-columns($gutter) {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: $gutter;

  & > * {
    flex: 1 1 0px;

    & > mat-form-field {
      width: 100%;
    }
  }

  &.wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  &.max-columns-1 {
    & > * {
      flex-basis: 100%;
    }
  }

  &.max-columns-2 {
    & > * {
      flex-basis: (100% / 2);
    }
  }

  &.max-columns-3 {
    & > * {
      flex-basis: (100% / 3);
    }
  }
}

@mixin x-message-base($filled-background: false, $msg-color: null, $panel-alpha: 0.15) {
  display: flex;
  color: $theme-color-text;
  font-weight: 500;

  > mat-icon {
    margin-right: 6px;
    color: $msg-color;
  }

  > *:nth-child(2) {
    line-height: 1.6;
    margin-top: 1px;
    flex: 1;
  }

  @if $filled-background {
    background: change-color($msg-color, $alpha: $panel-alpha);
    padding: 8px;
    border-radius: 6px;
    width: fit-content;

    > *:nth-child(2) {
      font-weight: 400;
    }
  }
}

@mixin warning-message($filled-background: false) {
  @include x-message-base($filled-background, $theme-color-warn-orange, 0.4);

  @if $filled-background ==false {
    color: $theme-color-warn-orange;
  }
}

@mixin info-message($filled-background: false) {
  @include x-message-base($filled-background, $theme-color-primary-darker, 0.15);
}

@mixin icon-size($size, $is-inside-button: false) {
  font-size: $size;
  height: $size;
  width: $size;

  @if $is-inside-button {
    margin: math.div(24px - $size, 2);
  }
}

@mixin icon-button-size($button-size, $icon-size) {
  @include mat.icon-button-density(math.div(48px - $button-size, -4px));

  mat-icon {
    @include icon-size($icon-size, true);
  }
}

@mixin output-form-field-font-size-topographies($target-font-size) {
  $nvpanalyzer-smaller-typography: map.deep-merge(vars.$nvpanalyzer-typography,
      (body-1: (font-size: $target-font-size),
        body-2: (font-size: $target-font-size),
        subtitle-1: (font-size: $target-font-size)));

  @include mat.form-field-typography($nvpanalyzer-smaller-typography);
  @include mat.input-typography($nvpanalyzer-smaller-typography);
  @include mat.select-typography($nvpanalyzer-smaller-typography);
}
