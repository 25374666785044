@use "@angular/material" as mat;
@use "@material/textfield";
@use "vars.scss";
@use "mixins.scss";
@use "sass:map";
@use "sass:math";

/**
* Many of the tweaks in this stylesheet are done to adjust the new Material to our liking.
* E.g. bringing down density or restoring some of the original styling that works better for us.
*
*  We need "!important" a lot, because these definitions target styles that get added later
* through component styles, and from a global stylesheet there is no other way to override them.
*/

// Default density is -2 for a more compact view.
// For some components we chose different densities.
@include mat.button-density(0);

@include mat.icon-button-density(math.div(48px - vars.$icon-button-size, -4px)); // 40px instead of 48px by default

@include mat.form-field-density(-4); // Form fields even more dense
@include mat.input-density(-4);
@include mat.select-density(-4);

// Regular buttons text in uppercase.
.mat-mdc-button.primary-button,
.mat-mdc-outlined-button.primary-button {
  text-transform: uppercase;
}

// Icon buttons hover effect twice as strong as default.
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08 !important;
}

// Menu, list, and select items are a bit too high. 48px -> 40px.
.mat-mdc-menu-item,
.mat-mdc-option {
  min-height: 40px !important;
}

.mat-mdc-list-item {
  height: 40px !important;
}

/**
* Form field density fixes
*/
mat-form-field {
  // Decrease density to minimum. By default, they are way too high.
  @include mat.chips-density(-4);

  .mat-mdc-select-arrow-wrapper {
    transform: unset !important;
  }

  // Undo "MDC hides the label in their density styles when the height decreases too much."
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) {
    .mat-mdc-floating-label {
      display: inherit;
    }

    .mat-mdc-form-field-infix {
      // Note that this makes the height of the form field 40px (without vertical margin)
      padding-bottom: 1px !important;
      padding-top: 15px !important;
    }

    label:not(.mdc-floating-label--float-above) {
      margin-top: 6px;
    }
  }

  // ::-webkit-file-upload-buttonfill the form field
  .mdc-text-field--filled {
    background-color: transparent !important;
    padding-left: 0;
    padding-right: 0;

    .mat-mdc-form-field-focus-overlay {
      background-color: transparent !important;
    }
  }

  .mdc-text-field--outlined .mat-mdc-form-field-infix {
    max-height: 40px;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
  }
}

mat-form-field {
  // Need to provide spacing together with subscriptSizing=dynamic:
  margin-bottom: 16px !important;

  mat-paginator & {
    margin-bottom: 0 !important;
    margin-top: -16px !important;
  }
}

// Table header color gray
.mdc-data-table__header-cell {
  color: vars.$theme-color-text-secondary;
}

// Default card padding
mat-card {
  mat-card-header {
    padding: 10px 20px !important;
  }

  mat-card-subtitle {
    padding: 0 20px !important;
  }
}

// Tabs a bit wider minimum width
.mdc-tab {
  min-width: 150px !important;
}

// All dialog buttons align right and a bit more padding
.mat-mdc-dialog-actions {
  justify-content: flex-end !important;
  gap: 8px !important;
  padding: 12px 16px !important;
}

// "Host element is no longer display: block which may affect layout. To fix layout issues add display: block back to the element."
mat-spinner {
  display: block;
}

// Increase space between slide toggle and label. Looks better.
mat-slide-toggle label {
  padding-left: 8px !important;
}

// Re-add divider to tab bar
mat-tab-header {
  border-bottom: 1px solid vars.$theme-color-divider;
}

// Mark form fields as `mat-form-field-readonly` for a different kind of styling
mat-form-field.mat-form-field-readonly {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-style: dotted;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: vars.$theme-color-text-secondary;
  }
}

// Default 22px for icons in non-icon buttons. Note that icon buttons default to 24px.
.mdc-button,
.mat-mdc-button,
.mat-button-toggle-button {
  // double notation to increase specifity
  mat-icon.mat-icon {
    @include mixins.icon-size(vars.$regular-button-icon-size, true);
  }
}

// Bring back original style of optgroups: grayish bold.
.mat-mdc-optgroup {
  color: vars.$theme-color-text-secondary !important;
  font-weight: 500 !important;
}

// Less large date picker icon button
mat-datepicker-toggle button {
  padding: math.div(vars.$formfield-button-size - vars.$formfield-button-icon-size, 2) !important;
  --mdc-icon-button-state-layer-size: #{vars.$formfield-button-size} !important;
  --mdc-icon-button-icon-size: #{vars.$formfield-button-icon-size} !important;
  transform: translateY(4px);
}

// Use these classes for 30px buttons.
.dense-icon-buttons {
  @include mixins.icon-button-size(vars.$icon-button-size-dense, vars.$icon-button-icon-size-dense);
}

.form-fields-small-font {
  @include mixins.output-form-field-font-size-topographies(13px);
}

// Increase fontsize expension panel header
mat-expansion-panel-header {
  --mat-expansion-header-text-size: 15px;
}
