@forward '_theme-variables.scss';

$header-height: 64px;
$footer-height: 32px;

$sidebar-togglebar-width: 12px;

$inline-icon-size: 22px; // E.g. status icons in tables, or icons in select trigger
$regular-button-icon-size: 22px;
$icon-button-icon-size: 24px;
$icon-button-size: 40px;
$icon-button-icon-size-dense: 22px;
$icon-button-size-dense: 30px;
$icon-button-icon-size-superdense: 18px;
$icon-button-size-superdense: 28px;
$formfield-button-icon-size: 18px;
$formfield-button-size: 26px;
